import { defineComponent, toRefs, reactive, computed, watch, nextTick, defineAsyncComponent } from "vue";
import API from "@/api/authority/index";
import { useMessage } from '@/hooks/web/useMessage';
import { useStore } from "vuex";
export default defineComponent({
  name: "AddAccount",
  components: {
    Eldialog: defineAsyncComponent(() => import("@/components/Eldialog/index.vue")),
    TypeSelect: defineAsyncComponent(() => import("@/components/TypeSelect/TypeSelect.vue")),
    OrganizeSelect: defineAsyncComponent(() => import("@/components/OrganizeSelect/index.vue"))
  },
  props: {
    activeOrg: {
      type: Object,
      default: {
        orgId: null,
        orgName: ''
      }
    },
    Info: {
      type: Object,
      default: null
    },
    visible: {
      type: Boolean,
      default: false
    },
    activeAccountId: {
      type: Number,
      default: null
    }
  },
  emits: ["handleSuccess", "update:visible"],
  setup(props, {
    emit
  }) {
    const message = useMessage();
    const {
      state
    } = useStore();
    const refData = reactive({
      searchValue: {
        id: 1,
        value: ""
      },
      value: "",
      activeValue: null,
      ruleForm: null,
      formData: null,
      title: '新增账号',
      roleList: [],
      rules: {
        username: [{
          required: true,
          message: "请输入账号名称",
          type: 'string',
          trigger: 'blur'
        }, {
          min: 1,
          max: 20,
          message: '长度在 1 到 20 个字符',
          trigger: 'blur'
        }, {
          pattern: /^[\u4E00-\u9FA5a-zA-Z0-9_]*$/,
          message: '用户名称不能带有特殊符号'
        }],
        nickname: [{
          required: true,
          message: "请填写账号昵称",
          type: 'string',
          trigger: 'blur'
        }, {
          min: 1,
          max: 20,
          message: '长度在 1 到 20 个字符',
          trigger: 'blur'
        }, {
          pattern: /^[\u4E00-\u9FA5a-zA-Z0-9_]*$/,
          message: '账号名称不能带有特殊符号'
        }],
        // password: [{
        //   required: true, message: "请填写密码", type: 'string', trigger: 'blur'
        // }],
        roleId: [{
          required: true,
          message: "请选择分配角色",
          type: 'number',
          trigger: 'change'
        }],
        orgId: [{
          required: true,
          message: "请选择所属组织",
          type: 'number'
        }]
      },
      confirm() {
        refData.ruleForm.validate(async valid => {
          if (valid) {
            const url = props.activeAccountId ? 'editUser' : 'addUser';
            const {
              code,
              msg
            } = await API[url](refData.formData);
            code == 0 && (emit('handleSuccess'), emit("update:visible", false));
            message[code == 0 ? 'success' : 'warning'](code == 0 ? `${props.activeAccountId ? '修改' : '添加'}成功` : msg);
          } else {
            console.log("error submit!!");
            return false;
          }
        });
      },
      async getData(id) {
        const {
          data,
          code,
          msg
        } = await API.getByIdUser({
          id
        });
        if (code == 0) {
          refData.searchValue.value = data.deptName;
          refData.searchValue.id = data.deptId;
          refData.formData = data;
          getRoleList();
        }
      },
      init() {
        refData.formData = {
          username: '',
          nickname: '',
          mobile: '',
          password: '',
          roleId: null,
          deptId: null
        };
      }
    });
    const defaultPassword = computed(() => {
      var _state$userInfo;
      return (_state$userInfo = state.userInfo) === null || _state$userInfo === void 0 ? void 0 : _state$userInfo.defaultPassword;
    });
    // 初始化表单
    refData.init();
    const show = computed({
      get: () => props.visible,
      set: val => {
        emit("update:visible", val);
      }
    });
    watch(() => props.visible, val => {
      if (val) {
        refData.init();
        if (props.activeAccountId !== null) {
          refData.title = '修改账号';
          refData.getData(props.activeAccountId);
        } else {
          refData.formData.deptId = props.activeOrg.orgId;
          refData.searchValue.value = props.activeOrg.orgName;
          refData.activeValue = props.activeOrg.activeValue;
          getRoleList();
          refData.title = '添加账号';
        }
        nextTick(() => {
          refData.ruleForm.clearValidate();
        });
      }
    }, {
      immediate: true
    });
    //选择组织
    const handleDoubleClick = data => {
      refData.formData.deptId = data.id;
      refData.formData.roleId = null;
      getRoleList();
    };
    //获取用户角色
    const getRoleList = async () => {
      const params = {
        deptId: refData.formData.deptId,
        name: '',
        pageSize: 1000,
        pageNo: 1
      };
      const {
        data
      } = await API.getRoleTable(params);
      console.log(data);
      refData.roleList = data.list;
    };
    return {
      ...toRefs(refData),
      show,
      handleDoubleClick,
      defaultPassword
    };
  }
});